<template>
    <button :type="type" class="btn-save">
        <font-awesome-icon icon="save" title="Pagina verwijderen"
                           class="w-full mt-1 mr-3 text-white"/>
        <slot></slot>
    </button>
</template>

<script>
    import { defineComponent } from 'vue'

    export default defineComponent({
        props: {
            type: {
                type: String,
                default: 'submit',
            },
        }
    })
</script>
